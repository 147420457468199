<template>
  <button
    class="btn btn-basic focus:outline-none leading-tight"
    :class="{ green: green }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'ButtonLegacy',
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    green: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.btn {
  transition: all 0.2s ease-in-out;
}
.btn:hover {
  @apply bg-brand-green;
}
.btn-basic {
  @apply inline-block cursor-pointer text-brand-black bg-brand-orange py-2 px-8;
  line-height: 1.1;
  @media only screen and (max-width: 1399px) {
    padding: 0.5rem 1.5rem;
  }
  @media only screen and (max-width: 767px) {
    padding: 0.5rem 1rem;
  }
}
.btn-black {
  @apply bg-black;
}
.green {
  @apply bg-brand-green;
}
.green:hover {
  @apply bg-black text-white;
}
.btn-black:hover {
  background-color: #6a6a6a;
  border-color: #6a6a6a;
}
.btn:disabled {
  background-color: #6a6a6a;
  @apply text-brand-dark-gray;
  cursor: not-allowed;
  pointer-events: all !important;
}
</style>
